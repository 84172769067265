// extracted by mini-css-extract-plugin
export var addQuestion = "client-questions-listing-module--add-question--88d24";
export var answerColumn = "client-questions-listing-module--answer-column--00e4d";
export var backgroundAnimation = "client-questions-listing-module--backgroundAnimation--4c819";
export var buttons = "client-questions-listing-module--buttons--369cc";
export var container = "client-questions-listing-module--container--fc836";
export var grid = "client-questions-listing-module--grid--d7e55";
export var isLoading = "client-questions-listing-module--is-loading--1ac5c";
export var link = "client-questions-listing-module--link--dbc34";
export var loader = "client-questions-listing-module--loader--2ebc7";
export var opacity = "client-questions-listing-module--opacity--91121";
export var questionColumn = "client-questions-listing-module--question-column--5a9ee";
export var radiusBox = "client-questions-listing-module--radius-box--abf4e";
export var sectionBox = "client-questions-listing-module--section-box--cfd35";
export var topbar = "client-questions-listing-module--topbar--a175f";