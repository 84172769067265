import alterpressApi from './base';

import {
    IClientEditQuestion,
    IClientEditQuestionsMutation,
    IClientQuestion,
} from '../../models/client-question.model';

export const clientQuestionApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['CLIENT_QUESTIONS'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getClientQuestionsList: builder.query<IClientQuestion[], string | undefined | null>({
                query: (language) => `client/questions${language ? `?lang=${language}` : ''}`,
                providesTags: ['CLIENT_QUESTIONS'],
            }),
            getClientEditQuestions: builder.query<IClientEditQuestion[], void>({
                query: () => `client/questions/form-data`,
                providesTags: ['CLIENT_QUESTIONS'],
            }),
            updateClientQuestions: builder.mutation<
                IClientEditQuestion,
                IClientEditQuestionsMutation
            >({
                query: (data) => ({
                    url: `client/questions`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_QUESTIONS'],
            }),
            deleteClientQuestion: builder.mutation<void, number | string>({
                query: (questionId) => ({
                    url: `client/questions/${questionId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['CLIENT_QUESTIONS'],
            }),
        }),
    });

export const {
    useGetClientQuestionsListQuery,
    useGetClientEditQuestionsQuery,
    useUpdateClientQuestionsMutation,
    useDeleteClientQuestionMutation,
} = clientQuestionApi;
