import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    container,
    grid,
    sectionBox,
    radiusBox,
    topbar,
    buttons,
    loader,
    questionColumn,
    answerColumn,
    addQuestion,
    isLoading,
    opacity,
    link,
} from './client-questions-listing.module.scss';

import { ISection } from '../../models/section.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import useTranslations from '../../hooks/use-translations';
import { useClientQuestion } from '../../hooks/use-client-question';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { useClientPermission } from '../../hooks/use-client-permission';
import { useLanguageConfig } from '../../hooks/use-language-config';
import { useModal } from '../../hooks/use-modal';

import Section from '../hoc/section';
import RadiusBox from '../hoc/radius-box';
import DashboardHeader from '../molecules/dashboard-header';
import Button from '../atoms/button';
import IconFactory from '../hoc/icon-factory';
import Loader from '../atoms/loader';
import DetailsColumn from '../atoms/details-column';
import NoPermissionInfo from '../organisms/no-permission-info';
import LanguageSelector, { ILanguageSelectorProps } from '../molecules/language-selector';

interface IClientQuestionsListing {
    className?: string;
    section: ISection;
}

interface IClientQuestionsListingQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const ClientQuestionsListing: React.FC<IClientQuestionsListing> = ({ className, section }) => {
    const { style, sectionId, css } = section;
    const t = useTranslations('ClientQuestionsListing');
    const { addModal } = useModal();
    const { locale } = usePageContext();
    const { languages } = useLanguageConfig();
    const { allPage } = useStaticQuery<IClientQuestionsListingQueryResult>(query);
    const [questionsLanguage, setQuestionsLanguage] = useState(locale);
    const clientQuestionsFormPage = usePagePathname(allPage, 'client-questions-form');
    const canQuestions = useClientPermission(['can-questions']);
    const questions = useClientQuestion({ queries: ['list'], language: questionsLanguage });
    const questionsData = questions.list.data;

    const handleQuestionDelete = (questionId: string | number) => {
        return () => {
            addModal({
                modalKey: 'delete-confirm-modal',
                modalProps: { onConfirm: handleQuestionDeleteConfirmation(questionId) },
            });
        };
    };

    const handleQuestionDeleteConfirmation = (questionId: string | number) => {
        return async () => {
            try {
                await questions.delete.fetch(questionId).unwrap();
            } catch {
                addModal({ modalKey: 'delete-error-modal' });
            }
        };
    };

    const handleLanguageChange: ILanguageSelectorProps['onLanguageSelect'] = (selectedLanguage) => {
        setQuestionsLanguage(selectedLanguage);
    };

    if (!canQuestions) {
        return <NoPermissionInfo isFullHeight={true} reason="role" />;
    }

    return (
        <Section
            className={`${sectionBox} ${className} ${questions.list.isLoading ? isLoading : ''} ${
                questions.delete.isLoading || questions.list.isFetching ? opacity : ''
            }`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={`${container}`}>
                <div className={topbar}>
                    <DashboardHeader hint={t.hint}>{t.title}</DashboardHeader>
                    <div className={buttons}>
                        <LanguageSelector
                            label={t.language}
                            onLanguageSelect={handleLanguageChange}
                            languages={languages}
                            activeLanguage={questionsLanguage}
                        />
                        <Button
                            stylePreset="secondary"
                            as="link"
                            shape="circle"
                            to={clientQuestionsFormPage}
                        >
                            <IconFactory icon="edit" />
                        </Button>
                    </div>
                </div>
                {questionsData ? (
                    <>
                        {questionsData.map((question, i) => {
                            return (
                                <RadiusBox
                                    className={radiusBox}
                                    key={`question-${question.questionId}`}
                                >
                                    <DetailsColumn
                                        label={`${t.question} ${i + 1}`}
                                        values={[question.label]}
                                        className={questionColumn}
                                    />
                                    <Button
                                        shape="circle"
                                        stylePreset="danger"
                                        onClick={handleQuestionDelete(question.questionId)}
                                    >
                                        <IconFactory icon={'trash'} />
                                    </Button>
                                    {question.options.length > 0 &&
                                        question.options.map((option, i) => {
                                            return (
                                                <DetailsColumn
                                                    label={`${t.answer} ${i + 1}`}
                                                    values={[option.label]}
                                                    className={answerColumn}
                                                />
                                            );
                                        })}
                                </RadiusBox>
                            );
                        })}
                        {questionsData.length < 2 && (
                            <div className={addQuestion}>
                                <Button shape={'circle'} as={'link'} to={clientQuestionsFormPage}>
                                    <IconFactory icon={'plus'} />
                                </Button>
                                <Link to={clientQuestionsFormPage} className={link}>
                                    {t.add}
                                </Link>
                            </div>
                        )}
                    </>
                ) : (
                    <Loader className={loader} />
                )}
            </div>
        </Section>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { in: ["client-questions-form"] } }) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
    }
`;

export default ClientQuestionsListing;
