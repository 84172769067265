import { useMemo } from 'react';

import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import {
    useGetClientQuestionsListQuery,
    useGetClientEditQuestionsQuery,
    useDeleteClientQuestionMutation,
    useUpdateClientQuestionsMutation,
} from '../redux/api/client-question';

export type TUseClientQuestionQueries = 'list' | 'edit';

interface IUseClientQuestionConfig {
    queries?: TUseClientQuestionQueries[];
    language?: string;
}

export const useClientQuestion = ({
    queries = [],
    language,
}: IUseClientQuestionConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');

    const skipQuestionsQuery = !queries.includes('list');
    const skipEditQuestionsQuery = !queries.includes('edit');

    const getQuestionsQuery = useGetClientQuestionsListQuery(language, {
        skip: skipQuestionsQuery,
    });
    const getEditQuestionsQuery = useGetClientEditQuestionsQuery(undefined, {
        skip: skipEditQuestionsQuery,
    });
    const [update, updateMutation] = useUpdateClientQuestionsMutation();
    const [deleteQuestion, deleteMutation] = useDeleteClientQuestionMutation();

    const questionsErrors = useMemo(() => {
        return getRtkQueryErrors(getQuestionsQuery.error, t.errors);
    }, [getQuestionsQuery.error, t.errors]);
    const editQuestionsErrors = useMemo(() => {
        return getRtkQueryErrors(getEditQuestionsQuery.error, t.errors);
    }, [getEditQuestionsQuery.error, t.errors]);
    const updateErrors = useMemo(() => {
        return getRtkQueryErrors(updateMutation.error, t.errors);
    }, [updateMutation.error, t.errors]);
    const deleteErrors = useMemo(() => {
        return getRtkQueryErrors(deleteMutation.error, t.errors);
    }, [deleteMutation.error, t.errors]);

    return {
        list: {
            data: getQuestionsQuery.data,
            isFetching: getQuestionsQuery.isFetching,
            isLoading: getQuestionsQuery.isLoading,
            isSuccess: getQuestionsQuery.isSuccess,
            isError: getQuestionsQuery.isError,
            errors: questionsErrors,
        },
        edit: {
            data: getEditQuestionsQuery.data,
            isFetching: getEditQuestionsQuery.isFetching,
            isLoading: getEditQuestionsQuery.isLoading,
            isSuccess: getEditQuestionsQuery.isSuccess,
            isError: getEditQuestionsQuery.isError,
            errors: editQuestionsErrors,
        },
        update: {
            fetch: update,
            data: updateMutation.data,
            isLoading: updateMutation.isLoading,
            isSuccess: updateMutation.isSuccess,
            isError: updateMutation.isError,
            errors: updateErrors,
        },
        delete: {
            fetch: deleteQuestion,
            data: deleteMutation.data,
            isLoading: deleteMutation.isLoading,
            isSuccess: deleteMutation.isSuccess,
            isError: deleteMutation.isError,
            errors: deleteErrors,
        },
    };
};
